import invoiceUtils from '@/utils/invoiceUtils';

class InvoiceMapper {
  mapOne (invoice = {}) {
    return {
      id: invoice.id,
      number: invoice.invoiceName,
      issueDate: invoice.issueDate,
      currency: invoice.currency,
      dueDate: invoice.dueDate,
      billingAccount: invoice.debtor?.billingAccountId,
      debtor: invoice.debtor,
      debtorName: invoice.debtor?.name,
      seller: invoice.creditor,
      invoiceType: invoice.type,
      amountWithTax: invoice.amountWithTax,
      amountExclusiveOfTax: invoice.amountExclusiveOfTax,
      outstandingAmount: invoice.outstandingAmount,
      temporaryOutstandingAmount: invoice.temporaryOutstandingAmount,
      status: invoice.status,
      isInvoice: invoiceUtils.isInvoice(invoice.type),
      isCreditNote: invoiceUtils.isCreditNote(invoice.type),
      linkedInvoices: invoiceUtils.getLinkedInvoices(invoice.linkedInvoices),
      comment: invoice.comment,
      pdfFilename: invoice.document?.documentName,
      refDoc: invoiceUtils.getRefDoc(invoice.type, invoice.invoiceName),
      externalRef: invoice.orderReference,
      paymentDate: invoice.paymentDate,
      settlementDate: invoice.settlementDate,
      isPaymentValidated: invoiceUtils.isPaymentValidated(invoice.status),
      isPaymentAccepted: invoiceUtils.isPaymentAccepted(invoice.status),
      oneOffPaymentLink: invoice.oneOffPaymentLink,
      language: invoice.language
    };
  }

  mapAll (invoices = []) {
    return (invoices || []).map(this.mapOne);
  }

  mapToInvoiceDtoV2 (invoice = {}) {
    return {
      id: invoice.id,
      invoiceName: invoice.number,
      issueDate: invoice.issueDate,
      currency: invoice.currency,
      dueDate: invoice.dueDate,
      billingAccount: invoice.debtor?.billingAccountId,
      debtor: invoice.debtor,
      debtorName: invoice.debtor?.name,
      creditor: invoice.seller,
      type: invoice.invoiceType,
      temporaryOutstandingAmount: invoice.temporaryOutstandingAmount,
      amountWithTax: invoice.amountWithTax,
      amountExclusiveOfTax: invoice.amountExclusiveOfTax,
      externalRef: invoice.orderReference
    };
  }
}

export default new InvoiceMapper();
