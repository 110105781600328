import Service from './Service';

class InvoiceService extends Service {
  getInvoices (billingAccount, statusList, page, pageSize, sortBy) {
    return this.instance.get(`invoiceapi/v1/invoices/${billingAccount}`, {
      params: {
        page: page.toString(),
        pageSize: pageSize.toString(),
        sortBy: sortBy,
        status: status.toString()
      }
    });
  }

  getInvoicesToValidate (debtorAccountId) {
    return this.getInvoicesByStatus(debtorAccountId, 'TO_VALIDATE');
  }

  getInvoicesToPay (debtorAccountId, requiredValidation) {
    const status = ['TO_PAY'];
    !requiredValidation && status.push('TO_VALIDATE');
    return this.getInvoicesByStatus(debtorAccountId, status);
  }

  getInvoicesByStatus (debtorAccountId, status) {
    return this.instance.get(`invoiceapi/v1/invoices/${debtorAccountId}`, {
      params: {
        status: status.toString(),
        pageSize: 100
      }
    });
  }

  getLateInvoices (debtorAccountId, invoiceNumber, { sortBy, sortDesc, page, itemsPerPage }) {
    const sort = sortBy.length === 1 ? sortBy[0] + ',' + (sortDesc[0] ? 'DESC' : 'ASC') : '';
    return this.instance.get(`invoiceapi/v1/invoices/${debtorAccountId}/overdue`, {
      params: {
        invoiceNumber,
        size: itemsPerPage,
        page: page - 1,
        sort
      }
    });
  }

  getUsableCreditNotes (debtorAccountId) {
    return this.instance.get(`invoiceapi/v1/invoices/${debtorAccountId}/usablecreditnotes`);
  }

  getInvoiceDetails (documentId) {
    return this.instance.get(`invoiceapi/v1/invoice/${documentId}`);
  }

  getLinkedCreditNotes (documentId, statusList) {
    return this.instance.get(`invoiceapi/v1/invoices/${documentId}/linkedcreditnotes`, {
      params: {
        status: statusList.toString()
      }
    });
  }

  getDocumentFileContent (invoiceId) {
    return this.instance.get(`invoiceapi/v1/invoices/${invoiceId}/content`, {
      responseType: 'blob'
    });
  }

  getPaginatedAndSortedInvoicesByStatus (debtorAccountId, status, invoiceNumber, { sortBy, sortDesc, page, itemsPerPage }) {
    const sort = sortBy.length === 1 ? sortBy[0] + ',' + (sortDesc[0] ? 'DESC' : 'ASC') : '';
    return this.instance.get(`invoiceapi/v1/invoices/${debtorAccountId}`, {
      params: {
        status: status,
        invoiceNumber,
        size: itemsPerPage,
        page: page - 1,
        sort
      }
    });
  }

  getPaginatedAndSortedInvoicesByDebtorIdsAndByStatusList (debtorIds, creditorId, statusList, overdue, filter,
    { sortBy, sortDesc, page, itemsPerPage }) {
    const sort = sortBy.length === 1 ? sortBy[0] + ',' + (sortDesc[0] ? 'DESC' : 'ASC') : '';
    return this.instance.post('invoiceapi/public/v2/invoices', debtorIds, {
      params: {
        status: statusList.toString(),
        creditorId: creditorId,
        filter: filter,
        overdue: overdue,
        size: itemsPerPage,
        page: page - 1,
        sort
      }
    });
  }

  getPaginatedNotPaidYetInvoicesByDebtorIds (debtorIds, creditorId, filter, { sortBy, sortDesc, page, itemsPerPage }) {
    const statuses = ['TO_VALIDATE', 'TO_PAY', 'PAID', 'SETTLED'];
    return this.getPaginatedAndSortedInvoicesByDebtorIdsAndByStatusList(debtorIds, creditorId, statuses, false,
      filter, { sortBy, sortDesc, page, itemsPerPage });
  }

  getPaginatedPaidInvoicesByDebtorIds (debtorIds, creditorId, filter, { sortBy, sortDesc, page, itemsPerPage }) {
    const statuses = ['PAID', 'SETTLED'];
    return this.getPaginatedAndSortedInvoicesByDebtorIdsAndByStatusList(debtorIds, creditorId, statuses, false,
      filter, { sortBy, sortDesc, page, itemsPerPage });
  }

  getPaginatedToPayInvoicesByDebtorIds (debtorIds, creditorId, filter, { sortBy, sortDesc, page, itemsPerPage }) {
    const statuses = ['TO_PAY', 'TO_VALIDATE'];
    return this.getPaginatedAndSortedInvoicesByDebtorIdsAndByStatusList(debtorIds, creditorId, statuses, false,
      filter, { sortBy, sortDesc, page, itemsPerPage });
  }

  getPaginatedRejectedInvoicesByDebtorIds (debtorIds, creditorId, filter, { sortBy, sortDesc, page, itemsPerPage }) {
    const statuses = ['REJECTED'];
    return this.getPaginatedAndSortedInvoicesByDebtorIdsAndByStatusList(debtorIds, creditorId, statuses, false,
      filter, { sortBy, sortDesc, page, itemsPerPage });
  }

  getPaginatedPaidInvoices (debtorAccountId, invoiceNumber, { sortBy, sortDesc, page, itemsPerPage }) {
    const statuses = ['PAID', 'SETTLED'];
    return this.getPaginatedAndSortedInvoicesByStatus(debtorAccountId, statuses.toString(),
      invoiceNumber, { sortBy, sortDesc, page, itemsPerPage });
  }

  validateInvoices (documents) {
    return this.instance.put('invoiceapi/v1/validatedocument', documents);
  }

  rejectInvoices (rejectionRequest) {
    return this.instance.post('invoiceapi/v1/reject', rejectionRequest);
  }

  createOneOffInvoice (invoice, creditorId) {
    return this.instance.post('invoiceapi/public/v2/one-off-front', invoice, {
      params: {
        creditorId: creditorId
      }
    });
  }
}

export default new InvoiceService();
