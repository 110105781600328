import invoiceMapper from '@/mappers/invoiceMapper';
class InvoiceUtils {
  getCustomFieldValue ({ valuesByCode }, code) {
    if (valuesByCode) {
      for (const [key, value] of Object.entries(valuesByCode)) {
        if (code === key && value.length) {
          return value[0].value;
        }
      }
    }
  }

  processCfValuesFromInvoice (invoice = {}, customFieldsMapping = []) {
    const valuesByCode = {};
    customFieldsMapping.forEach(({ customFieldKey, invoiceProperty }) => {
      valuesByCode[customFieldKey] = this.formatPropertyToCustomFieldValue(invoiceProperty, invoice);
    });
    return { valuesByCode };
  }

  formatPropertyToCustomFieldValue (property, invoice = {}) {
    let value;
    if (invoice[property]) {
      value = invoice[property];
    }
    return [{ priority: 0, value: value }];
  }

  isCreditNote (code) {
    return code === 'CREDIT_NOTE';
  }

  isInvoice (code) {
    return code === 'INVOICE';
  }

  getRefDoc (code, number) {
    return `${this.isInvoice(code) ? 'IN' : 'CN'} ${number}`;
  }

  getLinkedInvoices (invoices = []) {
    return invoiceMapper.mapAll(invoices);
  }

  isPaymentValidated (status) {
    return ['PAID', 'SETTLED'].includes(status);
  }

  isPaymentAccepted (status) {
    return status === 'PAID';
  }
}

export default new InvoiceUtils();
